import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MerchGalleryContent from "../components/GalleryContent/MerchGalleryContent"

function MerchGallery() {
  return (
    <Layout>
      <SEO title="Merch Gallery" />
      <MerchGalleryContent />
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

export default MerchGallery
